<template>
    <AsideWrapper :breakpoint="breakpointEnum" :class="[IsAsideOpened ? 'is-aside-opened' : 'is-aside-closed', IsAsideHidden && 'is-aside-hidden']">
        <template #aside>
            <NewAside
                v-if="props.asideEnabled"
                ref="asideHtmlEl"
                :is-admin="props.isAdminProp"
                :items="NavItems"
                :current-route="CurrentRoute"
                :breakpoint="asideToggleBreakpoint"
                :debounce-delay="debounceDelay"
                @route-click="ROUTE_CLICK"
                @window-resize="ON_WINDOW_RESIZE($event); windowWidth = $event"
                @toggle="emit(PAGE_WRAPPER_EMIT_EVENTS.ASIDE_TOGGLE, $event);
                         INIT_BREAKPOINT_ENUM({ asideOpened: $event });
                         U_FIRE_MANUAL_TABLE_RESIZE_EVENT({ isIncreased: !$event, resizeDelay: 400 })"
                @init="emit(PAGE_WRAPPER_EMIT_EVENTS.ASIDE_INIT, $event)"
            >
                <template #logo-icon>
                    <i
                        class="epicvpn-epic-shield"
                        @click="(!isTouchDevice || IsAsideOpened) && router.push({ name: 'home' })"
                    />
                </template>
                <template #logo-word>
                    <i
                        class="epicvpn-epicvpn-word"
                        @click="(!isTouchDevice || IsAsideOpened) && router.push({ name: 'home' })"
                    />
                </template>
            </NewAside>
        </template>

        <template #header>
            <NewHeader
                :avatar-attrs="AvatarAttrs" :type="props.headerType"
                :burger-is-active="burgerIsActive" :dropdown-nav-list="[]"
                :right-nav-list="RightNavList" @dropdown-close="console.log('dropdown-close')"
            >
                <template v-if="IsAsideHidden" #burger-prepend>
                    <NewBurger
                        class="new-header__burger is-prepend"
                        :opened="burgerIsActive" size="l"
                        @toggle="BURGER_ON_TOGGLE($event)"
                    />
                </template>

                <template v-if="props.headerType === ENUMS.HEADER_TYPES.LANDING_PAGE" #logo>
                    <RouterLink :to="{ path: `/${appStore.locale}` }" class="default-styled-logo">
                        <img :src="epicLogo" alt="epicvpn">
                    </RouterLink>
                </template>

                <template v-if="props.headerType === ENUMS.HEADER_TYPES.LANDING_PAGE && !IsMobile" #button>
                    <NewButton :href="`/${appStore.locale}`" :size="ENUMS.SIZES.M">
                        Get EPicVPN
                    </NewButton>
                </template>

                <template v-if="props.headerType === ENUMS.HEADER_TYPES.LANDING_PAGE && (IsMobile || IsTablet)" #burger-append>
                    <NewBurger
                        class="new-header__burger is-append" size="l" lines-alignment="right"
                        :opened="burgerIsActive" @toggle="burgerIsActive = $event"
                    />
                </template>

                <template v-if="props.headerType === ENUMS.HEADER_TYPES.USER_ADMIN" #append>
                    <NewContainer class="space-mt-l">
                        <h1 class="h1">
                            {{ title }}
                        </h1>
                        <NewTabs
                            is="router-link" v-if="props.tabs?.length"
                            :tabs="tabs" :size="ENUMS.SIZES.M"
                        />
                    </NewContainer>

                    <MobileNavMenu
                        :opened="burgerIsActive"
                        :items="NavItems"
                        :current-route="CurrentRoute"
                        @route-click="ROUTE_CLICK"
                        @close="burgerIsActive = false"
                    >
                        <template #header-logo>
                            <img :src="epicLogo" alt="epicvpn">
                        </template>
                    </MobileNavMenu>
                </template>
            </NewHeader>
        </template>

        <template #main>
            <main class="main" v-bind="props.mainAttrs">
                <NewLoader v-if="props.loading" size="2xl" class="main__loader" />
                <template v-else>
                    <!-- DEBUG INFO START -->
                    <DebugInfo v-if="DEBUG_ENABLED" :is-aside-opened="IsAsideOpened" :window-width="windowWidth" />
                    <!-- DEBUG INFO END -->

                    <NewContainer v-if="props.containerEnabled" v-bind="props.containerAttrs">
                        <slot v-if="$slots.content" name="content" />
                        <RouterView v-else />
                    </NewContainer>
                    <template v-else>
                        <slot v-if="$slots.content" name="content" />
                        <RouterView v-else />
                    </template>
                </template>
            </main>
        </template>

        <template #footer>
            <UserAdminFooter :nav-list="footerNavList" left-text="© 2024 EpicVPN. All rights reserved.">
                <template #select>
                    <NewLanguageSelect />
                </template>
            </UserAdminFooter>
        </template>
    </AsideWrapper>
</template>

<script setup lang="ts">
import {
    AsideWrapper,
    ENUMS,
    MobileNavMenu,
    NewAside,
    NewBurger,
    NewButton,
    NewHeader,
    NewLoader,
    NewTabs,
    U_FIRE_MANUAL_TABLE_RESIZE_EVENT,
    UserAdminFooter
} from 'vue-components2'

import type { Ref } from 'vue'
import { computed, inject, provide, ref, shallowRef } from 'vue'

import { useRouter } from 'vue-router'
import { U_GET_VIEWPORT_WIDTH, useDebounce, useEventListener } from 'platform-module'
import { usePageWrapper } from './PageWrapper'
import { useAppStore } from '@/stores'

import type { AsideHtmlReturn, PageWrapperProps } from '@/components/PageWrapper/types.ts'
import { PAGE_WRAPPER_EMIT_EVENTS } from '@/components/PageWrapper/types.ts'

import epicLogo from '~/images/svg/epic-logo.svg'
import DebugInfo from '@/components/DebugInfo.vue'
import NewLanguageSelect from '@/components/NewLanguageSelect.vue'

import type { BreakpointComposable } from '~/types'

const props = withDefaults(
    defineProps<PageWrapperProps>(),
    {
        tabs: [],
        title: 'Title',
        isAdminProp: false,
        loading: false,
        containerEnabled: true,
        asideEnabled: true,
        headerType: ENUMS.HEADER_TYPES.USER_ADMIN,
        mainAttrs: () => {},
        containerAttrs: () => {}
    }
)

const emit = defineEmits([
    PAGE_WRAPPER_EMIT_EVENTS.WINDOW_RESIZE,
    PAGE_WRAPPER_EMIT_EVENTS.ASIDE_TOGGLE,
    PAGE_WRAPPER_EMIT_EVENTS.ASIDE_INIT
])

const router = useRouter()
const windowWidth = shallowRef(window.innerWidth)
const debounceDelay = Number(import.meta.env.VITE_WINDOW_RESIZE_DEBOUNCE_DELAY || 30)
const appStore = useAppStore()
const DEBUG_ENABLED = true

const IsAsideHidden = computed(() => windowWidth.value < 768)

const asideHtmlEl: Ref<AsideHtmlReturn> = shallowRef<AsideHtmlReturn>({
    Items: [],
    opened: false,
    animationEnded: false,
    animationStarted: false,
    GET_INITIAL_STATE: () => false,
    TOGGLE: () => {}
})

const {
    breakpointEnum,
    asideToggleBreakpoint,
    INIT_BREAKPOINT_ENUM,
    IsMobile,
    IsTablet,
    IsDesktop
} = inject<BreakpointComposable>('breakpointComposable')

const isTouchDevice = inject<Ref<boolean>>('isTouchDevice', ref(false))

const {
    BURGER_ON_TOGGLE,
    ROUTE_CLICK,
    NavItems,
    CurrentRoute,
    AvatarAttrs,
    RightNavList,
    IsAsideOpened,
    burgerIsActive,
    footerNavList
} = usePageWrapper({
    props,
    asideHtmlEl,
    IsTablet,
    IsDesktop
})

function ON_WINDOW_RESIZE(windowWidth: any) {
    INIT_BREAKPOINT_ENUM({ asideOpened: IsAsideOpened.value })
    emit(PAGE_WRAPPER_EMIT_EVENTS.WINDOW_RESIZE, {
        asideOpened: IsAsideOpened.value,
        windowWidth
    })
}

if (!props.asideEnabled) {
    const debouncedResizeHandler = useDebounce(() => {
        ON_WINDOW_RESIZE(U_GET_VIEWPORT_WIDTH({
            scrollbarWidth: 12,
            isTouchDevice: isTouchDevice.value
        }))
    }, debounceDelay)

    useEventListener(window, 'resize', debouncedResizeHandler)
}

provide('IsAsideOpened', IsAsideOpened)
</script>

<style scoped lang="scss">
@use '~/styles/modules/container.scss' as *;

:deep(.new-header__container) {
    @extend .new-container;
}

:deep(.ua-footer__container) {
    @extend .new-container;
}

:deep(.new-header.user-admin-type) {
    --new-header-padding: var(--space-l) 0 0 0;
    --new-header-min-height: auto;
    --new-header-border-width: 2px;
}

.new-tabs {
    $tabs_left_offset: calc(-1 * var(--new-tab-padding-y));
    transform: translate($tabs_left_offset, var(--new-tab-before-height));
}

.new-burger {
    $burger_left_offset: calc(-1 * var(--new-burger-padding-l));
    $burger_right_offset: var(--new-burger-padding-l);

    &.is-prepend {
        transform: translate($burger_left_offset, 0);
    }

    &.is-append {
        transform: translate($burger_right_offset, 0);
    }
}
</style>
