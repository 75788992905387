<template>
    <div v-if="AppStore.isAppLoading" class="overlay">
        <AppLoader :type="2" />
    </div>
    <RouterView />
    <ModalConfirmation ref="modalConfirmation" />
    <PaypalOverlay
        :opened="AppStore.isPaypalOverlayOpened" @on-close="paypalOnClose" @on-continue="paypalOnContinue"
    />
    <StripeOverlay :opened="AppStore.isStripeOverlayOpened" />
    <LoadingOverlay
        background-color="#fff" color="#414141" :opened="AppStore.isLogoutOverlayOpened"
        text="You're being logged out..."
    />
    <LineGradientLoadingBar :loading="AppStore.redirectOverlay" />

    <Vpc v-if="mode === 'development'" />
</template>

<script>
import { ROLE_NAME, U_GET_AUTH_ROLE } from 'auth-module'
import { SUB_STATUSES, useNewAccountBillingStore } from 'billing-module'
import {
    DEBUG_ERROR,
    DEBUG_LOG,
    U_GET_COOKIE,
    U_OBJ_HAS_PROPERTY,
    useBreakpoint,
    useTouchDevice
} from 'platform-module'
import { LineGradientLoadingBar, LoadingOverlay, PaypalOverlay, StripeOverlay } from 'vue-components'
import { U_GET_INITIAL_ASIDE_STATE } from 'vue-components2'
import { computed, provide, watch } from 'vue'

import { useRouter } from 'vue-router'
import Vpc from './vite-plugin-checker.vue'

import { useAppStore, useAuthStore } from '@/stores'
import { WS } from '@/helpers/websocket.js'
import { MAX_BREAKPOINT_OBJ, MODULES_BOOLEANS } from '@/static'

export default {
    name: 'EpicVpnApp',

    components: {
        PaypalOverlay,
        StripeOverlay,
        LoadingOverlay,
        LineGradientLoadingBar,
        Vpc
    },

    setup() {
        const authStore = useAuthStore()
        const appStore = useAppStore()
        const { isTouchDevice } = useTouchDevice()
        const { INIT_SUB_WATCHER } = useNewAccountBillingStore()
        const router = useRouter()

        // TODO: optimize useBreakpoint initialization
        const toggleAsideBreakpoints = {
            [ROLE_NAME.USER]: 1096,
            [ROLE_NAME.ADMIN]: 1670,
            [ROLE_NAME.VPN_SERVER]: 1670,
            [ROLE_NAME.AUTHOR]: 1670,
            [ROLE_NAME.PUBLIC]: 1670
        }
        const breakpointComposable = useBreakpoint({
            toggleAsideBreakpoints,
            MAX_BREAKPOINT_OBJ,
            isTouchDevice
        })

        watch(() => authStore.user, (user) => {
            if (user) {
                const { INIT_BREAKPOINT_ENUM, SET_ASIDE_TOGGLE_BREAKPOINT } = breakpointComposable
                INIT_BREAKPOINT_ENUM({
                    asideOpened: U_GET_INITIAL_ASIDE_STATE({
                        breakpoint: breakpointComposable.asideToggleBreakpoint.value
                    })
                })

                SET_ASIDE_TOGGLE_BREAKPOINT(U_GET_AUTH_ROLE({ user }).ROLE)
            }
        })

        const User = computed(() => authStore.user)

        INIT_SUB_WATCHER({ user: User, router })

        provide('breakpointComposable', breakpointComposable)
        provide('isTouchDevice', isTouchDevice)
        provide('authStore', authStore)
        provide('appStore', appStore)
        provide('User', User)
        provide('modulesBooleans', MODULES_BOOLEANS)
        provide('projectName', import.meta.env.VITE_PROJECT_NAME)
        provide('projectIcon', 'epicvpn-logo')
    },

    data() {
        return {
            wsInited: false,
            mode: process.env.NODE_ENV
        }
    },

    watch: {
        User(user, oldUser) {
            DEBUG_LOG('user changed', user)
            const isLocalServer = import.meta.env.DEV
            const isSourceChanges = user && oldUser && oldUser.default_source !== user.default_source
            user && !WS.isConnected() && !isLocalServer && !this.wsInited && WS.connect(user.id)
            this.wsInited = !!user
            isSourceChanges && this.AccountBillingStore.M_SET_DEFAULT_SOURCE({ card_id: user.default_source })
            !user && !isLocalServer && WS.stopReconnecting()
            !user && !isLocalServer && WS.close()
            user && this.AppStore.M_HANDLE_ACCESS_PAGES({ user, routeNames: window.routeNames })
        },

        CurrentSub(subscription) {
            const roleObj = U_GET_AUTH_ROLE({ user: this.AuthStore.user })
            this.$route.name === 'billing.membership2' && !subscription && !this.AppStore.isLogoutOverlayOpened && this.$router.push({ name: 'billing.membership2' }) // redirect to billing/membership if sub deleted and user is on /billing/membership page
            DEBUG_LOG('SET SUBSCRIPTION WATCHER', subscription)
        }
    },

    mounted() {
        this.vm.config.globalProperties.$modalConfirmation = this.$refs.modalConfirmation
        provide('modalConfirmation', this.$refs.modalConfirmation)

        for (const key in window.configs.device)
            this.DevicesStore.state[key] = window.configs.device[key]

        for (const key in window.configs)
            this.AppStore.M_SET_APP_STORE_VALUE({ key, value: window.configs[key] })

        window.addEventListener('focus', this.sessionExpiredHandler)

        document.cookie = `epic_user_auth=${Boolean(
            U_GET_AUTH_ROLE({ user: this.AuthStore.user }).isUser
        )}; path=/`

        WS.onMessage = async (e) => {
            if (e.data) {
                DEBUG_LOG('data from WS', e.data)
                const action = String(e.data?.action).toLowerCase()
                const model = e.data?.model

                if (model === 'Subscription') {
                    this.wsSubscription({ action, data: e.data.data })
                }
                else if (model === 'Invoice') {
                    this.wsInvoices({ action, data: e.data.data })
                }
                else if (model === 'User') {
                    this.wsUser({ action, data: e.data.data })
                }
                else if (model === 'Source') {
                    this.wsSource({ action, data: e.data.data })
                }
                else if (model === 'UsersBillingAddress') {
                    this.wsUsersBillingAddress({ action, data: e.data.data })
                }
                else if (model === 'UsersDevice' && action === 'deleted' && this.User && e.data.data.device_id == this.DeviceStore.state.device_id) {
                    await this.AuthStore.LOGOUT({ is_overlay_enabled: false })
                    await this.router.push({
                        name: 'signin',
                        query: { continue: this.$route.name }
                    })
                }
            }
        }
    },

    methods: {
        async sessionExpiredHandler() {
            try {
                if (this.AuthStore.user && !U_GET_COOKIE('XSRF-TOKEN')) {
                    const { auth } = await this.AuthStore.AUTH_CHECK()

                    if (!auth) {
                        await this.AuthStore.LOGOUT({ is_overlay_enabled: false }) // logout if session is expired
                        await this.router.push({
                            name: 'signin',
                            query: { continue: this.$route.name, path: this.$route.path },
                            params: { lang: this.AppStore.locale }
                        })
                        window.epicWebSiteChannel.postMessage({ action: 'LOGOUT' })
                    }
                }
            }
            catch (error) {
                DEBUG_ERROR(error)
            }
        },

        paypalOnContinue() {
            setTimeout(() => window.paypalWindow && window.paypalWindow.focus(), 0)
        },

        paypalOnClose() {
            window.paypalWindow && window.paypalWindow.close()
            this.AppStore.M_SET_APP_STORE_VALUE({
                key: 'isPaypalOverlayOpened',
                value: false
            })
        },

        wsSubscription({ action, data }) {
            const {
                success_mark,
                status,
                subscription_id,
                created_at,
                has_cryptomus_processing_subscription,
                has_cryptomus_approval_pending_subscription,
                service_id
            } = data
            const statusLower = String(status).toLowerCase()
            const serviceId = Number(service_id)

            this.AccountBillingStore.has_cryptomus_processing_subscription = has_cryptomus_processing_subscription
            this.AccountBillingStore.has_cryptomus_approval_pending_subscription = has_cryptomus_approval_pending_subscription

            const latest = data.latest_subscription_except_approval
            const needChangeCurrentSub = this.CurrentSub && subscription_id == this.CurrentSub.subscription_id
            const needGetSubFromLatestSubscriptionExceptApproval = latest && new Date(latest.created_at).getTime() > new Date(created_at)

            DEBUG_LOG('needGetSubFromLatestSubscriptionExceptApproval', needGetSubFromLatestSubscriptionExceptApproval)

            const condition1 = action === 'updated'
            const condition2 = needChangeCurrentSub && serviceId !== 5 && statusLower !== SUB_STATUSES.FREEZED // update only current sub, ingnore bonus freezed sub
            const condition3 = serviceId === 5 && statusLower !== SUB_STATUSES.FREEZED // update ws if sub is bonus sub and sub is not freezed
            const condition4 = serviceId === 6 && success_mark // update ws if sub is cryptomus

            if (condition1 && (condition2 || condition3 || condition4)) {
                DEBUG_LOG('UPDATE SUB FROM WS', {
                    condition1,
                    condition2,
                    condition3,
                    condition4
                })
                this.AccountBillingStore.currentSub = needGetSubFromLatestSubscriptionExceptApproval
                    ? data.latest_subscription_except_approval
                    : data
            }
            else if (action === 'deleted' && needChangeCurrentSub) {
                this.AccountBillingStore.currentSub = null
                DEBUG_LOG('DELETE SUB FROM WS', {
                    needChangeCurrentSub,
                    wsSubscriptionId: subscription_id,
                    currentSubscriptionId: this.CurrentSub.subscription_id
                })
            }
            else if (action === 'created' && (success_mark || statusLower !== SUB_STATUSES.PENDING || (serviceId === 5 && statusLower !== SUB_STATUSES.FREEZED))) {
                // we ignore SUB_STATUSES.PENDING status with success_mark: 0
                this.AccountBillingStore.currentSub = needGetSubFromLatestSubscriptionExceptApproval
                    ? data.latest_subscription_except_approval
                    : data
            }
        },

        wsInvoices({ action, data }) {
            const isExists = this.AccountBillingStore.invoices && this.AccountBillingStore.invoices.findIndex(el => el.invoice_id == data.invoice_id) >= 0

            DEBUG_LOG(
                'this.AccountBillingStore.invoices',
                this.AccountBillingStore.invoices
            )
            DEBUG_LOG(
                'this.AccountBillingStore.membershipInvoices',
                this.AccountBillingStore.membershipInvoices
            )

            switch (action) {
                case 'updated':
                    isExists
                        ? this.AccountBillingStore.M_UPDATE_INVOICE({
                                invoice: data,
                                keys: ['invoices', 'membershipInvoices']
                            })
                        : this.AccountBillingStore.M_ADD_INVOICE({
                                invoice: data,
                                keys: ['invoices', 'membershipInvoices']
                            })
                    break
                case 'deleted':
                    this.AccountBillingStore.M_DELETE_INVOICE({
                        invoice: data,
                        keys: ['invoices', 'membershipInvoices']
                    })
                    break
                case 'created':
                    isExists
                        ? this.AccountBillingStore.M_UPDATE_INVOICE({
                                invoice: data,
                                keys: ['invoices', 'membershipInvoices']
                            })
                        : this.AccountBillingStore.M_ADD_INVOICE({
                                invoice: data,
                                keys: ['invoices', 'membershipInvoices']
                            })
                    break
                default:
                    break
            }
        },

        async wsUser({ action, data }) {
            switch (action) {
                case 'updated': {
                    const oldUser = this.AuthStore.user || null
                    const roleObj = U_GET_AUTH_ROLE({ user: data })
                    const isAdminOrAuthor = roleObj.isAdmin
                    const userCopy = this.AuthStore.user || {}
                    const { GET_CURRENT_SUB } = this.AccountBillingStore
                    const { M_SET_ACCESS_PAGES } = this.AppStore

                    this.AuthStore.M_SET_USER({ ...userCopy, ...data })

                    this.AccountBillingStore.M_SET_DEFAULT_SOURCE({
                        card_id: data.default_source
                    })

                    DEBUG_LOG('window.toast.state.toasts', window.toast.state.toasts)

                    // if user was LOGGED and DEACTIVATED and became REACTIVATED, then redirect him to dashboard
                    if (oldUser && oldUser.delete_at && data && !data.delete_at && oldUser.id == data.id) {
                        await GET_CURRENT_SUB({ modulesBooleans: MODULES_BOOLEANS })
                        this.router.push({
                            name: isAdminOrAuthor
                                ? 'admin.dashboard'
                                : 'dashboard'
                        })
                    }
                    else if (oldUser && !oldUser.delete_at && data && data.delete_at && oldUser.id == data.id) {
                        // if user was LOGGED and NOT DEACTIVATED and became DEACTIVATED, then redirect him to reactivate page
                        M_SET_ACCESS_PAGES(['reactivate-account'])
                        this.router.push({ name: 'reactivate-account' })
                        // if (!window.toast.state.toasts.length) await window.toast.add({ type: "destructive", text1: this.trans("toasts.delete_account") });
                    }
                    else if (oldUser && !oldUser.activated && data && data.activated && oldUser.id == data.id) {
                        // if user was LOGGED and NOT VERIFIED and became VERIFIED, then redirect him to dashboard
                        await GET_CURRENT_SUB({ modulesBooleans: MODULES_BOOLEANS })
                        await this.router.push({
                            name: isAdminOrAuthor
                                ? 'admin.dashboard'
                                : 'dashboard'
                        })
                        // if (!window.toast.state.toasts.length) await window.toast.add({ type: "success", text1: this.trans("toasts.email_verified") });
                    }
                    else if (oldUser && oldUser.activated && data && U_OBJ_HAS_PROPERTY(data, 'activated') && !data.activated && oldUser.id == data.id) {
                        // if user was LOGGED and VERIFIED and became NOT VERIFIED, then redirect him to email verify page
                        M_SET_ACCESS_PAGES(['email.verify'])
                        this.router.push({ name: 'email.verify' })
                    }

                    break
                }
            }
        },

        wsSource({ action, data: source }) {
            const isExists = this.AccountBillingStore.sources && this.AccountBillingStore.sources.findIndex(el => el.card_id === source.card_id) >= 0

            switch (action) {
                case 'deleted':
                    this.AccountBillingStore.M_DELETE_SOURCE({
                        card_id: source.card_id,
                        default_source: this.AuthStore.user.default_source
                    })
                    break
                case 'created': {
                    isExists
                        ? this.AccountBillingStore.M_UPDATE_SOURCE({
                                source,
                                default_source: this.AuthStore.user.default_source
                            })
                        : this.AccountBillingStore.M_ADD_SOURCE({
                                source,
                                default_source: this.AuthStore.user.default_source
                            })
                    break
                }
                case 'updated':
                    isExists
                        ? this.AccountBillingStore.M_UPDATE_SOURCE({
                                source,
                                default_source: this.AuthStore.user.default_source
                            })
                        : this.AccountBillingStore.M_ADD_SOURCE({
                                source,
                                default_source: this.AuthStore.user.default_source
                            })
                    break
                default:
                    break
            }
        },

        wsUsersBillingAddress({ action, data }) {
            switch (action) {
                case 'created':
                    this.AuthStore.user = {
                        ...this.User,
                        users_billing_address: {
                            ...this.User.users_billing_address,
                            ...data
                        }
                    }
                    break
                case 'updated':
                    this.AuthStore.user = {
                        ...this.User,
                        users_billing_address: {
                            ...this.User.users_billing_address,
                            ...data
                        }
                    }
                    break
                default:
                    break
            }
        }
    }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
</style>
